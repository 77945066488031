@import url(https://fonts.googleapis.com/css2?family=Concert+One&display=swap);
@charset "UTF-8";
/* ============ GOOGEL FONTS ================= */
/*
  * ─── BODY TEXT */
/* ========= BASE ============== */
html {
  font-size: 62.5%;
}

*,
::before,
::after {
  box-sizing: border-box;
}

::-webkit-scrollbar {
  display: none;
}

body {
  font-family: "Concert One", cursive;
  font-size: 1.5rem;
  display: grid;
  place-items: center;
  min-height: 100vh;
  text-transform: capitalize;
  background: linear-gradient(270deg, #000, #468944);
}

b {
  background: #0f5809;
}

.gameDetail {
  display: grid;
  text-align: center;
  padding: 1rem 2rem;
  line-height: 2.5rem;
  background: linear-gradient(168deg, #000, #70c86e);
  box-shadow: 0 0 8rem 2rem rgba(126, 255, 90, 0.5);
  border-radius: 2rem;
  color: #b2ffba;
  z-index: 100;
}

#canvas {
  margin-top: 5rem;
  width: 400px;
  height: 650px;
  box-shadow: 0 0 8rem 2rem rgba(126, 255, 90, 0.5);
  border: 2px double #1c291c;
}
main {
  padding: 5px;
}
table {
  color: #b2ffba !important;
  font-size: 2rem;
}
.btn, .page-link, .dropdown-item, .form-control{
  font-size: 1.8rem;
}
table tr td {
  overflow-wrap: anywhere;
}
